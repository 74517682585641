import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Assets/Styles/CategoryContent.css"; 
import { Base_Url,getAllProductAPI } from "./Apis";

const ProductList = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${Base_Url}${getAllProductAPI}`
        );
        const data = await response.json();
        const uniqueCategories = [
          ...new Set(data.products.map((product) => product.productCategory)),

        ];
        setCategories(uniqueCategories);
        setProducts(data.products);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleCategoryClick = (category) => {
    navigate(`/category/${category}`);
  };

  return (
    <div className="category-product-list-container ">
      <h3>Categories</h3>
      <ul className="category-product-list  category-has-scrollbar">
        {categories.map((category, index) => (
          <li
            key={index}
            className="category-product-item"
            onClick={() => handleCategoryClick(category)}
          >
            <div className="category-product-info">
              <img
                src={
                  products.find(
                    (product) => product.productCategory === category
                  ).imageURL[0]
                }
                alt={category}
                className="category-product-image"
              />
              <div className="category-product-category">{category}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductList;
