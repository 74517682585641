
// export const Base_Url = "https://tekiskymart-render.onrender.com";
// export const Base_Url = "https://tekiskymart-railway-production.up.railway.app";
export const Base_Url = "https://tekiskymart-huzaiflaptop-production.up.railway.app";

//  Product APIS

export const getAllProductAPI = "/product/approved";
export const saveOrderProductAPI = "/order/saveOrder";


//  Orders APIS

export const AllOrderAPI = "/order/getAllOrders";
export const AddProduct = "/product/addproduct";
export const AllUserAPI = "/user/getUsers";
export const updateUserAPI = "/user/updateUser/";
export const deleteUserAPI = "/user/deleteUser/";
export const getOneUserAPI = "/user/getOneUser/";
export const  createUserAPI = "/user/createUser";
export const  getEnquiryAPI = "/client/getEnquiry";
export const  loginAPI = "/user/login";
export const  preOrderAPI = "/pre/order";
export const  getpreOrderAPI = "/pre/getPreOrders";

export const getCategiesAPI = "/product/getcategories";

export const excelUploadAPI = "/customer/number"

export const allCustomersAPI = "/customer/allCustomerDetails"
